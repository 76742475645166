import React, { useState } from 'react';
import classnames from 'classnames';
import { Box } from '@mui/material';
import AddonCard from '../../../../components/AddonCard/AddonCard';
import AddonCardSkeleton from '../../../../components/AddonCard/AddonCard.Skeleton';
import {
	createURL,
	getAddonCatalogLink,
	getAddonsQueryVariables,
	getCurrentWatchingContentId,
	Queries,
	useMembershipContext,
	useUserContext
} from 'common';
import { HorizontalList } from 'components';

import useStyles from './MediathekAddons.styles';

type Props = {
	className?: string;
};

const MediathekAddons = (props: Props) => {
	const { className } = props;
	const userCtx = useUserContext();
	const { user } = userCtx;
	const { userMembership, isMember } = useMembershipContext();
	const { name: companyName } = user?.company || {};
	const [offset, setOffset] = useState(0);
	const classes = useStyles();
	const { data, loading: loading } = Queries.useGetAddOnCoursesQuery({
		variables: getAddonsQueryVariables(),
		skip: !companyName
	});

	const addonContents = data?.courses.data || [];

	const hasRegularMembership = isMember;
	const hasGroupMembership = Boolean(userMembership?.addedToGroupMembershipDateTime);

	if (!user || (!hasRegularMembership && !hasGroupMembership) || !companyName) {
		return null;
	}

	const pageSize = 3;

	return (
		<Box className={classnames(classes.container, className)}>
			<HorizontalList
				items={addonContents}
				renderItem={(addonContent) => {
					const progressLink = createURL(
						`/syrot/${addonContent.contentId}`,
						'catalog',
						true
					).url;
					const contentId = getCurrentWatchingContentId(addonContent.videos ?? []);
					const currentSchedule = addonContent.videos?.find(
						(video) => video.contentId === contentId
					);
					const courseLink = getAddonCatalogLink(
						addonContent.contentId,
						currentSchedule?.contentId
					);
					const doesSyrotHaveTests = addonContent.videos.some(
						(v) => v.questions.length > 0
					);
					return (
						<AddonCard
							fullContent={{
								...addonContent,
								videos: addonContent.videos.map((video) => ({
									contentId: video.contentId,
									contentType: video.contentType,
									contentProgress: video.contentProgress,
									endDateTime: video.endDateTime,
									id: video.id,
									title: video.title,
									questions: video.questions,
									product: video.product,
									replacementDate: video.replacementDate,
									source: video.source
								}))
							}}
							doesSyrotHaveTests={doesSyrotHaveTests}
							courseLink={courseLink}
							progressLink={progressLink}
							className={classes.addonCard}
							isCompact
						/>
					);
				}}
				numberOfItemsInRow={pageSize}
				totalCount={addonContents.length}
				offset={offset}
				onOffsetChange={setOffset}
				classes={{
					horizontalListContainer: classes.horizontalListContainer,
					item: classes.addonCardListItem
				}}
				renderSkeletonItem={() => (
					<AddonCardSkeleton className={classes.addonCard} isCompact />
				)}
				isSkeletonVisible={loading}
			/>
		</Box>
	);
};

export default MediathekAddons;
