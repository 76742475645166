import React from 'react';
import { Box } from '@mui/material';
import { getCurrentWatchingContentId, AddonCardContent, formatTranslation, Types } from 'common';
import { Button, Typography } from 'components';

import useStyles from './AddonCard.styles';
import AddonContentProgress from '../AddonContentProgress/AddonContentProgress';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

export type Props = {
	courseLink: string;
	doesSyrotHaveTests: boolean;
	progressLink: string;
	fullContent: {
		contentId: string;
		brand?: Types.ProductBrand | null;
		title?: string | null | undefined;
		subtitle?: string | null | undefined;
		className?: string;
		videos:
			| Array<{
					contentUnit?:
						| {
								title?: string | null | undefined;
						  }
						| null
						| undefined;
					title?: string | null | undefined;
					source?: {
						duration?: number | null | undefined;
						thumbnail?: string | null | undefined;
					} | null;
					contentProgress?:
						| {
								trackedDateTime?: Types.Scalars['DateTime'] | null | undefined;
								contentId: string;
								completed?: boolean | null | undefined;
								time?: number | null | undefined;
						  }
						| null
						| undefined;
					id?: string | undefined;
					endDateTime?: Types.Scalars['DateTime'] | null | undefined;
					replacementDate?: Types.Scalars['DateTime'] | null | undefined;
					contentType: Types.ContentType;
					questions?: Array<{ passed?: { passedAt?: string | null } | null }>;
					product?: {
						specialityIconUrl?: string | null | undefined;
					} | null;
					contentId: string | null | undefined;
			  }>
			| null
			| undefined;
		topicDescription?: Types.Scalars['JSON'] | null | undefined;
	};
	className?: string;
	classes?: Partial<ReturnType<typeof useStyles>>;
	title?: string;
	isCompact?: boolean;
};

const brandToFullName: Record<string, string> = {
	[Types.ProductBrand.Syrot]: 'Systematisches Rotationstraining',
	SYFAT: 'Systematisches Facharzttraining'
};

const AddonCard = (props: Props) => {
	const {
		fullContent,
		className,
		isCompact,
		progressLink,
		courseLink,
		title: titleProp,
		doesSyrotHaveTests
	} = props;
	const classes = useStyles(props);
	const { locale } = useIntl();
	const { brand, topicDescription } = fullContent;
	const topicTitle = formatTranslation(topicDescription, { locale });
	const title = titleProp || fullContent.title || `${brand}: ${topicTitle}`;
	const brandFullName = brand && brandToFullName[brand];

	const contentId = getCurrentWatchingContentId(fullContent.videos ?? []);
	const currentSchedule = fullContent.videos?.find((v) => v.contentId === contentId);

	const isContentStarted = !!currentSchedule;

	const actionButtonLocaleId = isContentStarted
		? 'common.addon-card.continue'
		: 'common.addon-card.start';

	return (
		<Box
			className={classnames(classes.container, isCompact && classes.compact, className)}
			data-testid="membership-addon-card"
		>
			<a className={classes.titleContainer} href={progressLink}>
				<Typography title={title} variant="h4" color="primary" />
				{brandFullName && (
					<Typography
						title={brandFullName}
						variant="body2"
						color="grey"
						colorVariant="500"
						mt={1}
					/>
				)}
			</a>
			<AddonContentProgress
				variant={doesSyrotHaveTests ? 'QUESTIONS' : 'PROGRESS'}
				progresses={fullContent.videos ?? []}
				className={classes.progressContainer}
			/>
			{isContentStarted && (
				<Box>
					<Typography
						localeId="common.addons.current-content"
						variant="subtitle2"
						color="primary"
						mb={0.5}
					/>
					<AddonCardContent
						contentId={fullContent.contentId}
						duration={currentSchedule.source?.duration}
						time={currentSchedule.contentProgress?.time}
						completed={currentSchedule.contentProgress?.completed}
						endDateTime={currentSchedule.endDateTime}
						replacementDate={currentSchedule.replacementDate}
						videoId={currentSchedule.contentId}
						brand={fullContent.brand}
						contentType={currentSchedule.contentType}
						specialityIconUrl={currentSchedule.product?.specialityIconUrl}
						thumbnailUrl={currentSchedule.source?.thumbnail}
						title={currentSchedule.title}
						subtitle={currentSchedule.contentUnit?.title}
						className={classes.currentContent}
					/>
				</Box>
			)}
			<Box className={classes.actionButtonContainer}>
				<Button
					localeId={actionButtonLocaleId}
					href={courseLink}
					endIcon="arrowRight"
					size="large"
					typographyProps={{
						className: classes.actionButtonTitle
					}}
					className={classnames(classes.actionButton, {
						[classes.actionButtonNotStarted]: !isContentStarted
					})}
					classes={{
						icon: classes.actionButtonIcon
					}}
				/>
			</Box>
		</Box>
	);
};

export default AddonCard;
